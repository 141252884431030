import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Hr } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про компанію | гольф-клуб Holʹf
			</title>
			<meta name={"description"} content={"Ознайомтеся з нашими найкращими послугами для гри в гольф"} />
			<meta property={"og:title"} content={"Про компанію | гольф-клуб Holʹf"} />
			<meta property={"og:description"} content={"Ознайомтеся з нашими найкращими послугами для гри в гольф"} />
			<meta property={"og:image"} content={"https://nexophase.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://nexophase.com/images/12303252.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://nexophase.com/images/12303252.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://nexophase.com/images/12303252.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://nexophase.com/images/12303252.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://nexophase.com/images/12303252.png"} />
			<meta name={"msapplication-TileImage"} content={"https://nexophase.com/images/12303252.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Про компанію
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
			У гольф-клубі Holʹf кожна послуга, яку ми пропонуємо, покликана покращити ваш досвід гри в гольф і задовольнити ваші особисті потреби. Від якості наших полів до різноманітних зручностей, ми гарантуємо, що кожен візит стане незабутнім. Наш клуб не лише надає виняткові можливості для гри в гольф, але й зосереджується на всебічному задоволенні потреб гостей.
			</Text>
			<Box
				display="flex"
				margin="40px 0 20px 0"
				justify-content="center"
				sm-flex-direction="column"
				flex-direction="row"
			>
				<Box padding="10px">
					<Image src="https://nexophase.com/images/6.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://nexophase.com/images/7.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://nexophase.com/images/8.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-7">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 80px 0px 0px"
					width="40%"
					lg-padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
					color="--greyD2"
					sm-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--dark" font="600 48px --fontFamily-sans">
					Наші особливі пропозиції
					</Text>
					<Text color="--greyD2" font="300 20px/140% --fontFamily-sansHelvetica">
					Ознайомтеся з нашими послугами для гри в гольф і дізнайтеся, чому ми є улюбленим місцем відпочинку як для початківців, так і для професіоналів.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="flex"
					flex-direction="column"
					lg-width="100%"
				>
					<Box
						min-width="100px"
						min-height="100px"
						justify-self="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-align-items="center"
						display="flex"
						flex-direction="row"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							padding="0px 0px 0px 50px"
							lg-padding="0px 0px 0px 35px"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
						>
							<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Найкращі поля для гольфу
							</Text>
							<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2" sm-margin="18px 0px 0px 0px">
							Різноманітність планів: Від складних лунок на схилах пагорбів до безтурботних фарватерів на березі озера - кожне поле має свої особливості та виклики.
							<br/><br/>
Удосконалене обслуговування: Ми використовуємо найсучасніші технології та екологічно чисті методи, щоб зберегти нашу зелень в первозданному вигляді цілий рік.
							<br/><br/>
Фірмові лунки: Відвідайте нашу знамениту 18-ту лунку, з її панорамним видом і стратегічним дизайном, вона є улюбленою серед членів клубу.

							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				lg-flex-direction="column"
			>
				
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 30px 0px 0px"
					width="40%"
					lg-padding="0px 20px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
					color="--greyD2"
					sm-padding="0px 0 0px 0px"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							padding="20px 0px 0px 50px"
							lg-padding="0px 0px 0px 35px"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
						>
							<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Тренування та вдосконалення
							</Text>
							<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2" sm-margin="18px 0px 0px 0px">
							Професійні тренування: вчіться у досвідчених професіоналів гольфу на індивідуальних або групових заняттях, адаптованих до вашого рівня майстерності.
							<br/><br/>
Клініки та семінари: Регулярні семінари, що охоплюють теми від основ для початківців до просунутих технік.
							<br/><br/>
Аналіз відео: Використовуйте найсучасніші технології для аналізу та покращення динаміки гойдалок.

							</Text>
						</Box>
					</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="flex"
					flex-direction="column"
					lg-width="100%"
				>
					<Box
						min-width="100px"
						min-height="100px"
						justify-self="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-align-items="center"
						display="flex"
						flex-direction="row"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							padding="20px 0px 0px 50px"
							lg-padding="0px 0px 0px 35px"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
						>
							<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Професійний магазин та обладнання
							</Text>
							<Text margin="22px 0px 0px 0px" font="300 18px/140% --fontFamily-sansHelvetica" color="--darkL2" sm-margin="18px 0px 0px 0px">
							Доступні найкращі бренди: В наявності найновіші бренди та спорядження для гольфу - від ключок до одягу.
							<br/><br/>
Індивідуальна фурнітура: Наші кваліфіковані співробітники допоможуть підібрати ключки, щоб покращити ваш замах і загальний рівень гри.
							<br/><br/>
Варіанти оренди: Високоякісні ключки та візки можна взяти напрокат, щоб мати найкраще, не приносячи свої власні.

							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://nexophase.com/images/9.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://nexophase.com/images/0.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Якщо ви хочете вдосконалити свою гру, відпочити в розкоші або просто насолодитися днем на полі, гольф-клуб Holʹf - ваш найкращий вибір. Завітайте до нас і дізнайтеся, чому наш клуб - це місце, де грають чемпіони і процвітають любителі гольфу. Відкрийте для себе більше, ніж просто гру - відкрийте для себе стиль життя.
				</Text>
			</Box>
		</Section>
		<Components.Banner />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});